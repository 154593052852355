import React from 'react';
import './App.css';

function App() {
  return (
    <div style={{ height: "100vh", width: "100vw", boxSizing: "border-box", padding: 0, margin: 0 }}>
      <img src="https://storage.googleapis.com/3d-container/apehouse_comming_soon.webp" style={{ height: "100vh", width: "100vw", }} alt='apehouse' />
    </div>
  );
}

export default App;
